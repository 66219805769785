import React from 'react'
import GlobalStyle from '../../../../shared/GlobalStyle/GlobalStyle'
import useTitle from '../../../../hooks/useTitle'

export default function StaffList() {
          useTitle('স্টাফ তালিকা');
          return (
                    <GlobalStyle>
                              <div className='my-5 md:my-0 px-2 lg:px-0'>
                                        <div className="border-l-4 border-red-500 bg-primary text-white py-3">
                                                  <h1 className='pl-4'>স্টাফ তালিকা</h1>
                                        </div>
                                        <div className='flex justify-center items-center h-[40vh]'>
                                                  <h1 className='text-xl font-bold'>স্টাফ তালিকা</h1>
                                        </div>
                              </div>
                    </GlobalStyle>
          )
}
