import React from 'react'
import GlobalStyle from '../../../../shared/GlobalStyle/GlobalStyle';
import useTitle from '../../../../hooks/useTitle';
import Headmaster from '../../../../assets/person/headmaster.jpg';

export default function AssistantHeadmaster() {
          useTitle('সহকারী প্রধান শিক্ষক');
          return (
                    <GlobalStyle>
                              <div className='my-5 md:my-0 px-2 lg:px-0'>
                                        <div className="border-l-4 border-red-500 bg-primary text-white py-3">
                                                  <h1 className='pl-4'>সহকারী প্রধান শিক্ষক</h1>
                                        </div>
                                        <div className='flex flex-col items-center gap-5 mt-5'>
                                                  <div>
                                                            <div className="w-full md:w-72 rounded-3xl ring ring-primary ring-offset-base-100 ring-offset-2">
                                                                      <img src={Headmaster} draggable={false} alt='Kanchan Bharat Chandra High School' className='aspect-square object-cover w-full rounded-3xl' />
                                                            </div>
                                                  </div>
                                                  <div className='flex flex-col justify-center items-center gap-0 md:gap-1 lg:gap-2'>
                                                            <p className='text-xl md:text-2xl lg:text-3xl font-bold'>মোঃ মজিবুর রহমান</p>
                                                            <p className='text-lg md:text-xl lg:text-2xl font-semibold'>সহকারী প্রধান শিক্ষক</p>
                                                            <p className='text-sm md:text-base lg:text-lg font-semibold'>কাঞ্চন ভারত চন্দ্র উচ্চ বিদ্যালয়</p>
                                                  </div>
                                        </div>
                                        <div className='flex flex-col justify-center items-center gap-0 md:gap-1 lg:gap-2 my-5'>
                                                  <p className='text-sm md:text-base lg:text-lg font-semibold'>মোবাইল নংঃ- <a href="tel:+8801758254874" className='md:hover:text-primary'>+8801758254874</a></p>
                                                  <p className='text-sm md:text-base lg:text-lg font-semibold'>ইমেইলঃ- <a href="mailto:mujibur@kbchs.edu.bd" className='md:hover:text-primary'>mujibur@kbchs.edu.bd</a></p>
                                        </div>
                                        <div className='mt-5 md:mt-10 px-2 md:px-0'>
                                                  <p className='text-sm md:text-base lg:text-lg text-justify'>আমি মোঃ মজিবুর রহমান, কাঞ্চন ভারত চন্দ্র উচ্চ বিদ্যালয়ের সহকারী প্রধান শিক্ষক। আমি এই প্রতিষ্ঠানের সহকারী প্রধান শিক্ষক হিসেবে বর্তমানে
                                                            কাজ করছি। </p>
                                        </div>
                              </div>
                    </GlobalStyle>
          )
}
