import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { BiSearchAlt } from 'react-icons/bi';
import { GoHomeFill } from 'react-icons/go';
import { FaHandPointRight } from 'react-icons/fa';
import { FaFacebook, FaSquareXTwitter, FaInstagram } from 'react-icons/fa6';
import FooterBg from '../../assets/footer_top_bg.png'
import Minister from '../../assets/person/minister.jpg'
import Minister2 from '../../assets/person/minister2.jpg'
import Minister3 from '../../assets/person/minister3.jpg'
import Minister4 from '../../assets/person/minister4.jpg'
import HeadMaster from '../../assets/person/headmaster.jpg'
import HotLine from '../../assets/999.png'
import NationalAnthem from '../../assets/bd_national_anthem.mp3'
import ScrollToTop from '../ScrollToTop';
import { notices } from '../../configs/globalData';
import axios from 'axios';
import { BASE_API } from '../../config';
import Navbar from '../Navbar/Navbar';
import toast from 'react-hot-toast';
import FooterCarousel from './FooterCarousel';
import BannerCarousel from './BannerCarousel';

type Props = {
          children: React.ReactNode
}

export default function GlobalStyle({ children }: Props) {
          const [userIP, setUserIP] = useState<string>('');

          const handleSearch = (e: React.FormEvent<HTMLFormElement>) => {
                    e.preventDefault();
                    toast.error('Search functionality is not available yet.');
          }

          useEffect(() => {
                    axios.get(`${BASE_API}/contact/get-ip`)
                              .then((res) => {
                                        setUserIP(res.data?.data?.ip);
                              })
                              .catch((error) => {
                                        console.error('Error fetching IP:', error);
                              });
          }, []);

          return (
                    <section className='lg:bg-[url("./assets/bg-main.gif")] bg-contain'>
                              <div className='flex items-center justify-center md:justify-between h-16 bg-primary px-5 lg:w-2/3 mx-auto'>
                                        <div className='hidden md:flex items-center gap-3 text-white'>
                                                  <Link to={'/'} className='p-2 rounded-full bg-primaryDark cursor-pointer'><GoHomeFill className='text-xl' /></Link>
                                                  <a href='/' target='_blank' rel='noreferrer' className='p-2 rounded-full bg-primaryDark border border-primary hover:bg-transparent hover:border-white duration-300 cursor-pointer'><FaFacebook className='text-xl' /></a>
                                                  <a href='/' target='_blank' rel='noreferrer' className='p-2 rounded-full bg-primaryDark border border-primary hover:bg-transparent hover:border-white duration-300 cursor-pointer'><FaInstagram className='text-xl' /></a>
                                                  <a href='/' target='_blank' rel='noreferrer' className='p-2 rounded-full bg-primaryDark border border-primary hover:bg-transparent hover:border-white duration-300 cursor-pointer'><FaSquareXTwitter className='text-xl' /></a>
                                        </div>
                                        <form onSubmit={handleSearch} className='flex items-center gap-1'>
                                                  <input type="text" className='input input-bordered input-sm bg-white rounded' placeholder='Search anything...' />
                                                  <button className='btn btn-sm bg-primaryDark hover:bg-primaryDark border-primary hover:border-primary shadow-lg text-white rounded'><BiSearchAlt size={20} />Search</button>
                                        </form>
                              </div>
                              <BannerCarousel />
                              <div className="hidden md:flex overflow-x-hidden lg:w-2/3 mx-auto scrolling-text-container bg-primary text-white py-4">
                                        <div className="scrolling-text flex items-center gap-10 md:gap-16 lg:gap-20">
                                                  {notices.slice(0, 6).map(notice => (
                                                            <Link to={`/notices/${notice.id}`} key={notice.id} className='flex items-center gap-2 md:cursor-pointer'><FaHandPointRight size={20} />{notice.title}</Link>
                                                  ))}
                                        </div>
                              </div>
                              <Navbar />
                              <div className="md:hidden overflow-x-hidden lg:w-2/3 mx-auto scrolling-text-container bg-primary text-white py-4">
                                        <div className="scrolling-text flex items-center gap-10 md:gap-16 lg:gap-20">
                                                  {notices.slice(0, 6).map(notice => (
                                                            <Link to={`/notices/${notice.id}`} key={notice.id} className='flex items-center gap-2 md:cursor-pointer'><FaHandPointRight size={20} />{notice.title}</Link>
                                                  ))}
                                        </div>
                              </div>
                              <div className='flex flex-col lg:flex-row gap-5 lg:gap-10 lg:w-2/3 mx-auto'>
                                        <div className='w-full lg:w-3/4'>
                                                  {children}
                                        </div>
                                        <div className='w-full lg:w-1/4'>
                                                  <div className='mb-4 lg:mb-8 px-2 lg:px-0'>
                                                            <div className="border-l-4 border-red-500 bg-primary text-white py-3">
                                                                      <h1 className='pl-4'>মাননীয় মন্ত্রী</h1>
                                                            </div>
                                                            <img src={Minister} alt="" draggable={false} className='w-full mt-4' />
                                                            <h1 className='text-xl md:text-2xl lg:text-2xl mt-4 lg:mt-5 font-bold text-center'>
                                                                      ডা. দীপু মনি <span className='text-sm md:text-base'>এম.পি.</span>
                                                            </h1>
                                                  </div>
                                                  <div className='mb-4 lg:mb-8 px-2 lg:px-0'>
                                                            <div className="border-l-4 border-red-500 bg-primary text-white py-3">
                                                                      <h1 className='pl-4'>মাননীয় উপমন্ত্রী</h1>
                                                            </div>
                                                            <img src={Minister2} alt="" draggable={false} className='w-full mt-4' />
                                                            <h1 className='text-xl md:text-2xl lg:text-2xl mt-4 lg:mt-5 font-bold text-center'>
                                                                      মহিবুল হাসান চৌধুরী <span className='text-sm md:text-base'>এম.পি.</span>
                                                            </h1>
                                                  </div>
                                                  <div className='mb-4 lg:mb-8 px-2 lg:px-0'>
                                                            <div className="border-l-4 border-red-500 bg-primary text-white py-3">
                                                                      <h1 className='pl-4'>সচিব</h1>
                                                            </div>
                                                            <img src={Minister3} alt="" draggable={false} className='w-full mt-4' />
                                                            <h1 className='text-xl md:text-2xl lg:text-2xl mt-4 lg:mt-5 font-bold text-center'>
                                                                      মোঃ কামাল হোসেন
                                                            </h1>
                                                  </div>
                                                  <div className='mb-4 lg:mb-8 px-2 lg:px-0'>
                                                            <div className="border-l-4 border-red-500 bg-primary text-white py-3">
                                                                      <h1 className='pl-4'>মহাপরিচালক</h1>
                                                            </div>
                                                            <img src={Minister4} alt="" draggable={false} className='w-full mt-4' />
                                                            <h1 className='text-xl md:text-2xl lg:text-2xl mt-4 lg:mt-5 font-bold text-center'>
                                                                      মোঃ কামাল হোসেন
                                                            </h1>
                                                  </div>
                                                  <div className='mb-4 lg:mb-8 px-2 lg:px-0'>
                                                            <div className="border-l-4 border-red-500 bg-primary text-white py-3">
                                                                      <h1 className='pl-4'>প্রধান শিক্ষক</h1>
                                                            </div>
                                                            <img src={HeadMaster} alt="" draggable={false} className='w-full mt-4' />
                                                            <h1 className='text-xl md:text-2xl lg:text-2xl mt-4 lg:mt-5 font-bold text-center'>
                                                                      মোঃ মজিবুর রহমান
                                                            </h1>
                                                            <div className='mt-2 flex justify-center'>
                                                                      <Link to={'/headmaster'} className='text-sm md:text-base text-primary'>( বিস্তারিত )</Link>
                                                            </div>
                                                  </div>
                                                  <div className='mb-4 px-2 lg:px-0'>
                                                            <div className="border-l-4 border-red-500 bg-primary text-white py-3">
                                                                      <h1 className='pl-4'>গ্যালারী</h1>
                                                            </div>
                                                            <FooterCarousel />
                                                  </div>
                                                  <div className='mb-4 px-2 lg:px-0'>
                                                            <div className="border-l-4 border-red-500 bg-primary text-white py-3">
                                                                      <h1 className='pl-4'>জরুরি হটলাইন</h1>
                                                            </div>
                                                            <div className='flex justify-start lg:justify-center w-1/2 md:w-1/3 lg:w-full'>
                                                                      <a href="tel:999"><img src={HotLine} alt="" draggable={false} className='rounded-lg mt-4' /></a>
                                                            </div>
                                                  </div>
                                                  <div className='mb-4 px-2 lg:px-0'>
                                                            <div className="border-l-4 border-red-500 bg-primary text-white py-3">
                                                                      <h1 className='pl-4'>জাতীয় সংগীত</h1>
                                                            </div>
                                                            <div className='mt-4 w-full'>
                                                                      <audio src={NationalAnthem} controls controlsList={'nodownload noplaybackrate'} className='w-full md:w-1/3 lg:w-full'></audio>
                                                            </div>
                                                  </div>
                                                  <div className='mb-8 lg:mb-8 px-2 lg:px-0'>
                                                            <div className="border-l-4 border-red-500 bg-primary text-white py-3">
                                                                      <h1 className='pl-4'>আপনার আইপি অ্যাড্রেস</h1>
                                                            </div>
                                                            <p className='text-base md:text-lg pt-6 font-semibold pb-4 text-start'>{userIP}</p>
                                                  </div>
                                        </div>
                              </div>
                              <div className='w-full lg:w-2/3 flex flex-col mx-auto px-2 md:px-4 lg:px-0'>
                                        <img src={FooterBg} alt="Footer" draggable={false} className='bg-auto w-full' />
                                        <div className='py-6 flex items-center gap-3'>
                                                  <Link to={'/contact-us'} className='text-sm md:hover:bg-primary md:hover:text-white duration-300 px-3 py-2'>অভিযোগ</Link>
                                                  <Link to={'/contact-us'} className='text-sm md:hover:bg-primary md:hover:text-white duration-300 px-3 py-2'>যোগাযোগ</Link>
                                                  <Link to={'/contact-us'} className='text-sm md:hover:bg-primary md:hover:text-white duration-300 px-3 py-2'>পরামর্শ</Link>
                                        </div>
                                        <h1 className='text-xs md:text-sm pb-6' title='Kanchan Bharat Chandra High School'><span className='font-semibold'>পরিকল্পনা ও বাস্তবায়নেঃ- </span> <Link to={'/headmaster'}>প্রধান শিক্ষক</Link> ( কাঞ্চন ভারত চন্দ্র উচ্চ বিদ্যালয় )</h1>
                                        <h1 className='text-xs md:text-sm pb-6'><span className='font-semibold'>ডিজাইন ও ডেভেলপমেন্টঃ-</span> <a href="https://toufiqhasankiron.com" target='_blank' rel="noreferrer">তৌফিক হাসান কিরণ</a> ( প্রাক্তণ শিক্ষার্থী, বিজ্ঞান, সেশনঃ- ২০১৯-২০২০ )</h1>
                                        <h1 className='text-xs md:text-sm pb-6' title='Kanchan Bharat Chandra High School'>কপিরাইট &copy; {new Date().getFullYear()} কাঞ্চন ভারত চন্দ্র উচ্চ বিদ্যালয়</h1>
                              </div>
                              <ScrollToTop />
                    </section>
          )
}
