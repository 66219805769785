import React from 'react'
import GlobalStyle from '../../shared/GlobalStyle/GlobalStyle'
import useTitle from '../../hooks/useTitle'
import { notices } from '../../configs/globalData'
import { useNavigate } from 'react-router-dom';
import useScrollToTop from '../../hooks/useScrollToTop';

export default function Notices() {
          useTitle('নোটিশ বোর্ড');
          useScrollToTop();
          const navigate = useNavigate();

          return (
                    <GlobalStyle>
                              <div className='my-5 md:my-0 px-2 lg:px-0'>
                                        <div className="overflow-x-auto">
                                                  <table className="min-w-full divide-y divide-black border border-black">
                                                            <thead>
                                                                      <tr>
                                                                                <th className="md:px-6 py-6 text-center md:text-left text-base leading-4 font-semibold uppercase tracking-wider w-1/3">
                                                                                          তারিখ
                                                                                </th>
                                                                                <th className="md:px-6 py-6 text-center md:text-left text-base leading-4 font-semibold uppercase tracking-wider w-2/3">
                                                                                          শিরোনাম
                                                                                </th>
                                                                      </tr>
                                                            </thead>
                                                            <tbody>
                                                                      {
                                                                                notices.map(notice => (
                                                                                          <tr key={notice.id} className='border border-black'>
                                                                                                    <td className="px-2 md:px-6 py-6 whitespace-no-wrap flex flex-col cursor-pointer text-sm md:text-base border-r border-r-black" onClick={() => navigate(`/notices/${notice.id}`)}>
                                                                                                              <span>{notice.publishedDate}</span>
                                                                                                              <span className='font-bold'>Views: {notice.views}</span>
                                                                                                    </td>
                                                                                                    <td className='md:hover:underline md:hover:text-primary px-2 md:px-6 text-sm md:text-base text-start cursor-pointer duration-300' onClick={() => navigate(`/notices/${notice.id}`)}>{notice.title}</td>
                                                                                          </tr>
                                                                                ))
                                                                      }
                                                            </tbody>
                                                  </table>
                                        </div>
                                        <div className='flex flex-col md:flex-row items-center md:justify-between justify-center pt-5 gap-3 w-full'>
                                                  <p className='text-lg font-bold'>Page 1 of 4 - Total - {notices.length}</p>
                                                  <div className="join">
                                                            <button className="join-item btn btn-sm md:btn-md btn-active btn-outline">1</button>
                                                            <button className="join-item btn btn-sm md:btn-md btn-outline">2</button>
                                                            <button className="join-item btn btn-sm md:btn-md btn-outline">3</button>
                                                            <button className="join-item btn btn-sm md:btn-md btn-outline">4</button>
                                                  </div>
                                        </div>
                                        <p className='pt-10 text-sm'>সর্বশেষ হাল-নাগাদঃ 13 Oct 2023</p>
                              </div>
                    </GlobalStyle>
          )
}