import React from 'react'
import Notice from '../../assets/bg-notice-board.png';
import GlobalStyle from '../../shared/GlobalStyle/GlobalStyle'
import { BiSolidRightArrow } from 'react-icons/bi';
import { cards, notices } from '../../configs/globalData';
import { Link, useNavigate } from 'react-router-dom';

export default function Home() {
  const navigate = useNavigate();

  return (
    <GlobalStyle>
      <div className='border mb-4 pb-2'>
        <img src={Notice} alt="" draggable={false} />
        <div className='mt-5 px-3'>
          <h1 className='font-semibold pb-2'>নোটিশ বোর্ড</h1>
          <ul className='leading-relaxed text-sm md:text-base'>
            {
              notices.slice(0, 6).map(notice => (
                <li key={notice.id} className='flex items-center gap-2 leading-loose lg:leading-normal hover:underline cursor-pointer' onClick={() => navigate(`/notices/${notice.id}`)}>
                  <BiSolidRightArrow className='text-primary' />
                  <span className='hidden md:flex'>{notice.title}</span>
                  <span className='md:hidden'>{notice.title.slice(0, 45)}...</span>
                </li>
              ))
            }
          </ul>
          <div className='flex justify-end'>
            <Link to={'/notices'} className='underline mt-2 font-semibold cursor-pointer'>সকল</Link>
          </div>
        </div>
      </div>
      <div className='pb-4 px-2 lg:px-0'>
        <div className="border-l-4 border-red-500 bg-primary text-white py-3">
          <h1 className='pl-4'>আমাদের দৃষ্টিঃ-</h1>
        </div>
        <p className='text-xl md:text-xl lg:text-2xl mt-5 border p-4 text-center'>“ বিশ্ব বাজারের জন্য শ্রেষ্ঠ মানের আউটপুট।”</p>
      </div>
      <div className='pb-4 px-2 lg:px-0'>
        <div className="border-l-4 border-red-500 bg-primary text-white py-3">
          <h1 className='pl-4'>আমাদের লক্ষ্যঃ-</h1>
        </div>
        <p className='text-sm md:text-base mt-5 border p-4 text-center'>“ বাংলাদেশের প্রযুক্তিগত এবং আর্থ সামাজিক উন্নয়নে অবদান রাখার জন্য ব্যাপকভাবে শিল্পের সঙ্গে মান সম্মত শিক্ষা ও প্রশিক্ষণ, সঠিক নির্দেশনা এবং পরামর্শ, সহযোগিতা প্রদানের মাধ্যমে কাঞ্চন ভারত চন্দ্র উচ্চ বিদ্যালয়কে স্বনির্ভরশীল প্রমাণ করা। ”</p>
        <p className='text-lg md:text-xl lg:text-2xl font-bold text-primary mt-5 border p-4 text-center'>মূল্যবোধঃ- নেতৃত্ব, ইনোভেশন, ত্যাগ, এক্সেলেন্স এবং প্রতিষ্ঠা।</p>
      </div>
      <div className='pb-4 px-2 lg:px-0'>
        <div className="border-l-4 border-red-500 bg-primary text-white py-3">
          <h1 className='pl-4'>পরিচিতিঃ-</h1>
        </div>
        <p className='text-sm md:text-base pt-2 pb-4 text-start' title='Kanchan Bharat Chandra High School'>কাঞ্চন ভারত চন্দ্র উচ্চ বিদ্যালয় গণপ্রজাতন্ত্রী বাংলাদেশ সরকারের শিক্ষা মন্ত্রনালয়ের উচ্চ মাধ্যমিক শিক্ষা অধিদপ্তরাধীন একটি সরকারী শিক্ষা প্রতিষ্ঠান। এতে বাংলাদেশের উচ্চ মাধ্যমিক শিক্ষা বোর্ড কর্তৃক ৪ বছরের ডিপ্লোমা-ইন-ইঞ্জিনিয়ারিং শিক্ষা কার্যক্রম পরিচালিত হয়। ঐতিহ্যবাহী নারায়ণগঞ্জ শহরের প্রবেশ পথে নারায়ণগঞ্জ পুলিশ লাইন্স-এর বিপরীতে শেরপুর রোডে শোভনীয় দালান কোঠায় সজ্জিত ঐতিহ্যবাহী কাঞ্চন ভারত চন্দ্র উচ্চ বিদ্যালয় অবস্থিত। ১৯৬২ সালে নারায়ণগঞ্জ শহরের কেন্দ্রস্থলে ( সাত মাথায় ) তদানিন্তন এডওয়ার্ড ইন্ডাষ্ট্রিয়াল স্কুলের প্রাচীর ঘেরা ভবনে প্রতিষ্ঠান এর কার্যক্রম শুরু হয়। সিভিল ও পাওয়ার টেকনোলজি নিয়েই যাত্রা শুরু করেছিল এই প্রতিষ্ঠানটি।</p>
        <p className='text-sm md:text-base pb-4 text-start' title='Kanchan Bharat Chandra High School'>পরবর্তিতে উচ্চ মাধ্যমিক শিক্ষার প্রসারতার লক্ষ্যে কাঞ্চন ভারত চন্দ্র উচ্চ বিদ্যালয় নামকরণে শেরপুর রোডের বর্তমান ভোকেশনাল টিচার্স ট্রেনিং প্রতিষ্ঠান ক্যাম্পাসে বিভিন্ন টেকনোলজির সমন্বয়ে প্রতিষ্ঠান স্থানান্তর করা হয় এবং ১৯৭৭ সালে বর্তমান ক্যাম্পাসে প্রতিষ্ঠানের যাত্রা শুরু হয়। সরকারী সিদ্ধান্তের আলোকে পরবর্তীতে আরও টেকনোলজির সংজোযন করা হয়। বিশ্ব-ব্যাংকের আইডিএ ক্রেডিটের অধীনে নির্মিত হয় বর্তমান অবকাঠামো।</p>
        <p className='text-sm md:text-base pb-4 text-start' title='Kanchan Bharat Chandra High School'>কাঞ্চন ভারত চন্দ্র উচ্চ বিদ্যালয় সবুজ ছায়া ঘেরা বৃক্ষরাজীতে পরিবেষ্ঠিত এবং প্রতিষ্ঠানের একাডেমিক কাম প্রশাসনিক ভবনের সামনে আলোক সজ্জায় সজ্জিত ফোয়ারা সম্বলিত মনোমুগ্ধকর ফুলের বাগান অবস্থিত। ১৮ একর জমির উপর নির্মিত এ প্রতিষ্ঠানটিতে ৩ তলা সুরম্য ভবন সমূহের একাডেমিক কাম প্রশাসনিক ভবন, আবাসিক ভবন, ছাত্রাবাস, ছাত্রীনিবাস ইত্যাদির সন্নিবেশ ঘটেছে। প্রতিষ্ঠানের প্রবেশ পথের গেটে, একাডেমিক কাম প্রশাসনিক ভবনের গেটে এবং ছাত্রাবাসসমূহ ও ছাত্রীনিবাসের প্রবেশের গেটসমূহে রয়েছে সুসজ্জিত তরুণ ও কর্তব্যনিষ্ঠ নিরাপত্তা প্রহরী, যারা সার্বক্ষনিক প্রয়োজনীয় যোগাযোগের মাধ্যমসহ নিরাপত্তা ও আগমনকারীদের তথ্য সংগ্রহ করেছে।</p>
      </div>
      <div className='px-2 lg:px-0 grid grid-cols-1 md:grid-cols-2 gap-5 md:gap-10'>
        {
          cards.map(card => (
            <div key={card.id} className='shadow-md px-6 py-10 mb-4 border'>
              <h1 className='font-semibold pb-4 md:pb-8 text-lg lg:text-xl'>{card.title}</h1>
              <div className='flex justify-center gap-10'>
                <div className='w-1/4'>
                  <img src={card.image} draggable={false} alt="Kanchan Bharat Chandra High School" className='w-full' />
                </div>
                <div className='w-3/4'>
                  {card.links.map(link => (
                    <Link key={link.id} to={link.url} className='text-sm mt-2 cursor-pointer flex items-center gap-1'><BiSolidRightArrow className='text-primary' /><span className='hover:underline hover:text-primary duration-300'>{link.title}</span></Link>
                  ))}
                </div>
              </div>
            </div>
          ))
        }
      </div>
    </GlobalStyle>
  )
}
