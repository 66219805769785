import { useEffect, useState } from "react";

const useTitle = (titleText: string) => {
          const [title, setTitle] = useState("");

          useEffect(() => {
                    document.title = titleText + " - কাঞ্চন ভারত চন্দ্র উচ্চ বিদ্যালয়";
                    setTitle(titleText);
          }, [titleText]);

          return [title];
};

export default useTitle;
