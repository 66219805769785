import React, { useState, useEffect } from 'react'
import { BiChevronLeft, BiChevronRight } from 'react-icons/bi'
import { slides } from '../../configs/globalData';

export default function FooterCarousel() {
          const [footerCurrentIndex, setFooterCurrentIndex] = useState<number>(0);

          const filteredSlides = slides?.slice(0, 5);

          const footerNextSlide = () => {
                    const isLastSlide = footerCurrentIndex === filteredSlides?.length - 1;
                    const newIndex = isLastSlide ? 0 : footerCurrentIndex + 1;
                    setFooterCurrentIndex(newIndex);
          }

          const footerPrevSlide = () => {
                    const isFirstSlide = footerCurrentIndex === 0;
                    const newIndex = isFirstSlide ? filteredSlides?.length - 1 : footerCurrentIndex - 1;
                    setFooterCurrentIndex(newIndex);
          }

          useEffect(() => {
                    const footerInterval = setInterval(() => {
                              const isLastSlide = footerCurrentIndex === filteredSlides?.length - 1;
                              const newIndex = isLastSlide ? 0 : footerCurrentIndex + 1;
                              setFooterCurrentIndex(newIndex);
                    }, 5000);
                    return () => clearInterval(footerInterval);
          }, [footerCurrentIndex, filteredSlides]);

          return (
                    <div className='h-[200px] md:h-[350px] lg:h-[180px] w-full mx-auto mt-4 relative'>
                              <div
                                        style={{ backgroundImage: `url(${slides[footerCurrentIndex]?.url})` }}
                                        className='w-full h-full rounded-md bg-center bg-cover duration-500 transform border'
                              ></div>
                              <button onClick={footerPrevSlide} className='absolute top-1/2 left-2 transform -translate-y-1/2 bg-white bg-opacity-50 hover:bg-opacity-70 duration-300 rounded-md'><BiChevronLeft size={30} /></button>
                              <button onClick={footerNextSlide} className='absolute top-1/2 right-2 transform -translate-y-1/2 bg-white bg-opacity-50 hover:bg-opacity-70 duration-300 rounded-md'><BiChevronRight size={30} /></button>
                              <ul className='absolute bottom-2 md:bottom-3 left-1/2 transform -translate-x-1/2 flex items-center gap-2'>
                                        {filteredSlides?.map((slide, index) => (
                                                  <li key={index} className={`w-3 h-3 rounded-full bg-white md:cursor-pointer ${footerCurrentIndex === index ? 'bg-opacity-100' : 'bg-opacity-50'}`} onClick={() => setFooterCurrentIndex(index)}></li>
                                        ))}
                              </ul>
                    </div>
          )
}
