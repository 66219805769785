export const links = [
          {
                    name: "আমাদের সম্পর্কে",
                    subMenu: true,
                    subLinks: [
                              {
                                        head: "পরিচিতি",
                                        subLink: [
                                                  {
                                                            name: "সংক্ষিপ্ত পরিচিতি",
                                                            link: "/short-intro"
                                                  },
                                                  {
                                                            name: "লক্ষ্য ও উদ্দেশ্য",
                                                            link: "/aims-and-objectives"
                                                  },
                                                  {
                                                            name: "বাণী ( প্রধান শিক্ষক )",
                                                            link: "/headmaster-message"
                                                  },
                                        ]
                              },
                              {
                                        head: "প্রধানগণ",
                                        subLink: [
                                                  {
                                                            name: "প্রধান শিক্ষক",
                                                            link: "/headmaster"
                                                  },
                                                  {
                                                            name: "সহকারী প্রধান শিক্ষক",
                                                            link: "/assistant-headmaster"
                                                  },
                                                  {
                                                            name: "প্রাক্তণ প্রধান শিক্ষকগণ",
                                                            link: "/former-headmasters"
                                                  },
                                        ]
                              },
                              {
                                        head: " কর্মকর্তা ও কর্মচারী",
                                        subLink: [
                                                  {
                                                            name: " শিক্ষক / কর্মকর্তাগণের তালিকা",
                                                            link: "/teachers-list"
                                                  },
                                                  {
                                                            name: "স্টাফ তালিকা",
                                                            link: "/staffs-list"
                                                  },
                                        ]
                              }
                    ],
          },
          {
                    name: "একাডেমিক",
                    subMenu: true,
                    subLinks: [
                              {
                                        head: "",
                                        subLink: [
                                                  {
                                                            name: "ক্লাস রুটিন",
                                                            link: "/class-routine"
                                                  },
                                                  {
                                                            name: "পরীক্ষার রুটিন",
                                                            link: "/exam-routine"
                                                  },
                                        ]
                              }
                    ],
          },
          {
                    name: "পরীক্ষার ফলাফল",
                    subMenu: true,
                    subLinks: [
                              {
                                        head: "",
                                        subLink: [
                                                  {
                                                            name: "ফলাফল",
                                                            link: "/results"
                                                  },
                                        ]
                              }
                    ],
          },
          {
                    name: "গ্যালারি",
                    subMenu: false,
                    link: "/gallery"
          },
          {
                    name: "শিক্ষার্থীদের তথ্য",
                    subMenu: true,
                    subLinks: [
                              {
                                        head: "",
                                        subLink: [
                                                  {
                                                            name: "বর্তমান শিক্ষার্থীদের তথ্য",
                                                            link: "/present-students"
                                                  },
                                                  {
                                                            name: "প্রাক্তণ শিক্ষার্থীদের তথ্য",
                                                            link: "/former-students"
                                                  }
                                        ]
                              }
                    ],
          },
          {
                    name: "যোগাযোগ",
                    subMenu: false,
                    link: "/contact-us"
          },
];