import React from 'react'
import GlobalStyle from '../../../shared/GlobalStyle/GlobalStyle'
import useTitle from '../../../hooks/useTitle'
import useScrollToTop from '../../../hooks/useScrollToTop';

export default function FormerStudents() {
          useTitle('প্রাক্তণ শিক্ষার্থীদের তথ্য');
          useScrollToTop();

          return (
                    <GlobalStyle>
                              <div className='my-5 md:my-0 px-2 lg:px-0'>
                                        <div className="border-l-4 border-red-500 bg-primary text-white py-3">
                                                  <h1 className='pl-4'>প্রাক্তণ শিক্ষার্থীদের তথ্য</h1>
                                        </div>
                                        <div className='flex flex-col justify-center items-center h-[40vh]'>
                                                  <h1 className='text-xl font-bold'>প্রাক্তণ শিক্ষার্থীদের তথ্য</h1>
						  <p>Ok</p>
                                        </div>
                              </div>
                    </GlobalStyle>
          )
}
