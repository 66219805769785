import React from 'react'
import GlobalStyle from '../../../../shared/GlobalStyle/GlobalStyle'
import useTitle from '../../../../hooks/useTitle'

export default function FormerHeadmasters() {
          useTitle('প্রাক্তণ প্রধান শিক্ষকগণ');

          return (
                    <GlobalStyle>
                              <div className='my-5 md:my-0 px-2 lg:px-0'>
                                        <div className="border-l-4 border-red-500 bg-primary text-white py-3">
                                                  <h1 className='pl-4'>প্রাক্তণ প্রধান শিক্ষকগণ</h1>
                                        </div>
                                        <div className='flex justify-center items-center h-[40vh]'>
                                                  <h1 className='text-xl font-bold'>প্রাক্তণ প্রধান শিক্ষকগণ</h1>
                                        </div>
                              </div>
                    </GlobalStyle>
          )
}
