import React from 'react'
import { Outlet } from 'react-router-dom';
import Home from '../pages/Home/Home';
import ScrollToTop from '../shared/ScrollToTop';

export default function Root() {
          document.title = 'কাঞ্চন ভারত চন্দ্র উচ্চ বিদ্যালয়';
          return (
                    <>
                              <Home />
                              <ScrollToTop />
                              <Outlet />
                    </>
          )
}
