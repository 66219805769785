import Image1 from '../assets/wallpapers/1.jpg'
import Image2 from '../assets/wallpapers/2.jpg'
import Image3 from '../assets/wallpapers/3.jpg'
import Image4 from '../assets/wallpapers/4.jpg'
import Image5 from '../assets/wallpapers/5.jpg'
import Image6 from '../assets/wallpapers/6.jpg'
import Image7 from '../assets/wallpapers/7.jpg'
import Image8 from '../assets/wallpapers/8.jpg'
import Image9 from '../assets/wallpapers/9.jpg'
import Image10 from '../assets/wallpapers/10.jpg'

import Card1 from '../assets/cards/institute.jpg'
import Card2 from '../assets/cards/notice.png'
import Card3 from '../assets/cards/info.png'
import Card4 from '../assets/cards/gallery.png'

export const notices = [
          {
                    id: "1",
                    title: "6th, 7th & 8th Mid Year Examination Seat and Room Planning",
                    noticeURL: "https://res.cloudinary.com/kirondev/image/upload/v1697188631/Toufiq_Hasan_Kiron_-_ReactJS_Developer_ccpdi2.pdf",
                    publishedDate: "08 Oct, 2023 12:10 PM",
                    views: 0,
          },
          {
                    id: "2",
                    title: "৬ষ্ঠ, ৭ম, ৯ম শ্রেণীর অর্ধবার্ষিক পরীক্ষার সময় সূচি।",
                    noticeURL: "https://res.cloudinary.com/kirondev/image/upload/v1697188631/Toufiq_Hasan_Kiron_-_ReactJS_Developer_ccpdi2.pdf",
                    publishedDate: "08 Oct, 2023 12:10 PM",
                    views: 0,
          },
          {
                    id: "3",
                    title: "বিশ্ব শিক্ষক দিবস উদযাপন সংক্রান্ত বিজ্ঞপ্তি।",
                    noticeURL: "https://res.cloudinary.com/kirondev/image/upload/v1697188631/Toufiq_Hasan_Kiron_-_ReactJS_Developer_ccpdi2.pdf",
                    publishedDate: "08 Oct, 2023 12:10 PM",
                    views: 0,
          },
          {
                    id: "4",
                    title: " পবিত্র ঈদ-ই-মিলাদুন্নবী (সা.) উদযাপন সংক্রান্ত বিজ্ঞপ্তি।",
                    noticeURL: "https://res.cloudinary.com/kirondev/image/upload/v1697188631/Toufiq_Hasan_Kiron_-_ReactJS_Developer_ccpdi2.pdf",
                    publishedDate: "08 Oct, 2023 12:10 PM",
                    views: 0,
          },
          {
                    id: "5",
                    title: "২০২৩-২৪ শিক্ষা বর্ষে ৬ষ্ঠ শ্রেণীতে সুযোগপ্রাপ্ত শিক্ষার্থীদের তালিকা",
                    noticeURL: "https://res.cloudinary.com/kirondev/image/upload/v1697188631/Toufiq_Hasan_Kiron_-_ReactJS_Developer_ccpdi2.pdf",
                    publishedDate: "08 Oct, 2023 12:10 PM",
                    views: 0,
          },
          {
                    id: "6",
                    title: "২০২৩-২৪ সেশনে ৬ষ্ঠ শ্রেণীতে ভর্তি সংক্রান্ত নােটিশ।",
                    noticeURL: "https://res.cloudinary.com/kirondev/image/upload/v1697188631/Toufiq_Hasan_Kiron_-_ReactJS_Developer_ccpdi2.pdf",
                    publishedDate: "08 Oct, 2023 12:10 PM",
                    views: 0,
          },
          {
                    id: "7",
                    title: "”আখেরি চাহার সোম্বা” উপলক্ষে প্রতিষ্ঠান বন্ধ সংক্রান্ত বিজ্ঞপ্তি।",
                    noticeURL: "https://res.cloudinary.com/kirondev/image/upload/v1697188631/Toufiq_Hasan_Kiron_-_ReactJS_Developer_ccpdi2.pdf",
                    publishedDate: "08 Oct, 2023 12:10 PM",
                    views: 0,
          },
          {
                    id: "8",
                    title: "শোক বার্তা",
                    noticeURL: "https://res.cloudinary.com/kirondev/image/upload/v1697188631/Toufiq_Hasan_Kiron_-_ReactJS_Developer_ccpdi2.pdf",
                    publishedDate: "08 Oct, 2023 12:10 PM",
                    views: 0,
          },
          {
                    id: "9",
                    title: "পুনঃ ভর্তি সংক্রান্ত বিজ্ঞপ্তি",
                    noticeURL: "https://res.cloudinary.com/kirondev/image/upload/v1697188631/Toufiq_Hasan_Kiron_-_ReactJS_Developer_ccpdi2.pdf",
                    publishedDate: "08 Oct, 2023 12:10 PM",
                    views: 0,
          },
          {
                    id: "10",
                    title: "ডেঙ্গু রোগের প্রাদুর্ভাব রোধ করার উপায় ও প্রতিরোধ সংক্রান্ত",
                    noticeURL: "https://res.cloudinary.com/kirondev/image/upload/v1697188631/Toufiq_Hasan_Kiron_-_ReactJS_Developer_ccpdi2.pdf",
                    publishedDate: "08 Oct, 2023 12:10 PM",
                    views: 0,
          },
];

export const slides = [
          {
                    url: Image1,
          },
          {
                    url: Image2,
          },
          {
                    url: Image3,
          },
          {
                    url: Image4,
          },
          {
                    url: Image5,
          },
          {
                    url: Image6,
          },
          {
                    url: Image7,
          },
          {
                    url: Image8,
          },
          {
                    url: Image9,
          },
          {
                    url: Image10,
          },
];

export const cards = [
          {
                    id: "1",
                    title: "প্রতিষ্ঠান সম্পর্কিত",
                    image: Card1,
                    links: [
                              {
                                        id: "1",
                                        title: "প্রতিষ্ঠানের সংক্ষিপ্ত ইতিহাস",
                                        url: "/short-intro",
                              },
                              {
                                        id: "2",
                                        title: "লক্ষ্য ও উদ্দেশ্য",
                                        url: "/aims-and-objectives",
                              },
                              {
                                        id: "3",
                                        title: "প্রতিষ্ঠান কাঠামো ও অবকাঠামো",
                                        url: "/",
                              },
                    ]
          },
          {
                    id: "2",
                    title: "নোটিশ",
                    image: Card2,
                    links: [
                              {
                                        id: "1",
                                        title: "নোটিশ",
                                        url: "/notices",
                              },
                    ]
          },
          {
                    id: "3",
                    title: "তথ্য বাতায়ন",
                    image: Card3,
                    links: [
                              {
                                        id: "1",
                                        title: "বর্তমান শিক্ষার্থীদের তথ্য",
                                        url: "/present-students",
                              },
                              {
                                        id: "2",
                                        title: "প্রাক্তণ শিক্ষার্থীদের তথ্য",
                                        url: "/former-students",
                              },
                    ]
          },
          {
                    id: "4",
                    title: "গ্যালারি",
                    image: Card4,
                    links: [
                              {
                                        id: "1",
                                        title: "ইন্সটিটিউটের বিভিন্ন কার্যক্রমের চিত্র",
                                        url: "/gallery",
                              },
                    ]
          },
];

export const teachers = [
          {
                    id: "1",
                    name: "মোঃ আবু সাঈদ আহমেদ",
                    designation: "প্রধান শিক্ষক",
                    image: "https://upload.wikimedia.org/wikipedia/commons/7/7c/Profile_avatar_placeholder_large.png",
                    mobile: "01711-111111",
                    joinedDate: "13 Oct 2023",
                    email: "abusayedahmed@gmail.com",
                    qualification: "MSc",
                    pims: "7743146209",
                    socials: [
                              {
                                        id: "1",
                                        title: "Facebook",
                                        url: "https://www.facebook.com",
                              },
                              {
                                        id: "2",
                                        title: "Twitter",
                                        url: "https://www.twitter.com",
                              },
                              {
                                        id: "3",
                                        title: "Instagram",
                                        url: "https://www.instagram.com",
                              },
                    ]
          },
          {
                    id: "2",
                    name: "মোঃ আবু সাঈদ আহমেদ",
                    designation: "প্রধান শিক্ষক",
                    image: "https://upload.wikimedia.org/wikipedia/commons/7/7c/Profile_avatar_placeholder_large.png",
                    mobile: "01711-111111",
                    joinedDate: "13 Oct 2023",
                    email: "abusayedahmed@gmail.com",
                    qualification: "MSc",
                    pims: "7743146209",
                    socials: [
                              {
                                        id: "1",
                                        title: "Facebook",
                                        url: "https://www.facebook.com",
                              },
                              {
                                        id: "2",
                                        title: "Twitter",
                                        url: "https://www.twitter.com",
                              },
                    ]
          },
          {
                    id: "3",
                    name: "মোঃ আবু সাঈদ আহমেদ",
                    designation: "প্রধান শিক্ষক",
                    image: "https://upload.wikimedia.org/wikipedia/commons/7/7c/Profile_avatar_placeholder_large.png",
                    mobile: "01711-111111",
                    joinedDate: "13 Oct 2023",
                    email: "abusayedahmed@gmail.com",
                    qualification: "MSc",
                    pims: "7743146209",
                    socials: [
                              {
                                        id: "1",
                                        title: "Twitter",
                                        url: "https://www.twitter.com",
                              },
                              {
                                        id: "2",
                                        title: "Instagram",
                                        url: "https://www.instagram.com",
                              },
                    ]
          },
          {
                    id: "4",
                    name: "মোঃ আবু সাঈদ আহমেদ",
                    designation: "প্রধান শিক্ষক",
                    image: "https://upload.wikimedia.org/wikipedia/commons/7/7c/Profile_avatar_placeholder_large.png",
                    mobile: "01711-111111",
                    joinedDate: "13 Oct 2023",
                    email: "abusayedahmed@gmail.com",
                    qualification: "MSc",
                    pims: "7743146209",
                    socials: [
                              {
                                        id: "1",
                                        title: "Facebook",
                                        url: "https://www.facebook.com",
                              },
                              {
                                        id: "2",
                                        title: "Instagram",
                                        url: "https://www.instagram.com",
                              },
                    ]
          },
          {
                    id: "5",
                    name: "মোঃ আবু সাঈদ আহমেদ",
                    designation: "প্রধান শিক্ষক",
                    image: "https://upload.wikimedia.org/wikipedia/commons/7/7c/Profile_avatar_placeholder_large.png",
                    mobile: "01711-111111",
                    joinedDate: "13 Oct 2023",
                    email: "abusayedahmed@gmail.com",
                    qualification: "MSc",
                    pims: "7743146209",
                    socials: [
                              {
                                        id: "1",
                                        title: "Facebook",
                                        url: "https://www.facebook.com",
                              },
                              {
                                        id: "2",
                                        title: "Twitter",
                                        url: "https://www.twitter.com",
                              },
                              {
                                        id: "3",
                                        title: "Instagram",
                                        url: "https://www.instagram.com",
                              },
                    ]
          },
];