import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import { HiOutlineMenuAlt4 } from 'react-icons/hi';
import { IoCloseSharp } from 'react-icons/io5';
import { GoHomeFill } from 'react-icons/go';
import NavLinks from './NavLinks';

export default function Navbar() {
          const [isMobileMenuOpen, setIsMobileMenuOpen] = useState<boolean>(false);
          const [heading, setHeading] = useState<string>('');
          const [subHeading, setSubHeading] = useState<string>('');

          return (
                    <>
                              <ul className='px-4 lg:px-0 hidden md:flex justify-start items-center gap-1 lg:w-2/3 mx-auto'>
                                        <Link to={'/'}><GoHomeFill size={25} /></Link>
                                        <NavLinks />
                              </ul>
                              <div className='mt-2 px-4 py-2 pb-3 md:hidden flex justify-between items-center'>
                                        <Link to={'/'} className='flex items-center gap-1'>
                                                  <GoHomeFill size={22} />
                                                  <span className='font-semibold pt-1'>হোম</span>
                                        </Link>
                                        <button
                                                  onClick={() => {
                                                            setIsMobileMenuOpen(!isMobileMenuOpen);
                                                            setHeading('');
                                                  }}
                                                  className='py-1 px-3 bg-primary rounded-lg text-white'>
                                                  {isMobileMenuOpen ?
                                                            (<IoCloseSharp size={25} />)
                                                            :
                                                            (<HiOutlineMenuAlt4 size={25} />)
                                                  }
                                        </button>
                              </div>
                              <ul className={`md:hidden flex-col gap-2 p-2 lg:w-2/3 mx-auto z-10 w-full ease-in-out transition-all duration-500 ${isMobileMenuOpen ? 'flex' : 'hidden'}`}>
                                        <NavLinks
                                                  heading={heading}
                                                  setHeading={setHeading}
                                                  subHeading={subHeading}
                                                  setSubHeading={setSubHeading}
                                                  setIsMobileMenuOpen={setIsMobileMenuOpen}
                                        />
                              </ul>
                    </>
          )
}
