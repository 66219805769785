import React from 'react'
import GlobalStyle from '../../../../shared/GlobalStyle/GlobalStyle';
import useTitle from '../../../../hooks/useTitle';
import useScrollToTop from '../../../../hooks/useScrollToTop';

export default function AimsAndObjectives() {
          useTitle('লক্ষ্য ও উদ্দেশ্য');
          useScrollToTop();

          return (
                    <GlobalStyle>
                              <div className='my-5 md:my-0 px-2 lg:px-0'>
                                        <div className='pb-4'>
                                                  <div className="border-l-4 border-red-500 bg-primary text-white py-3">
                                                            <h1 className='pl-4'>আমাদের দৃষ্টিঃ-</h1>
                                                  </div>
                                                  <p className='text-xl md:text-xl lg:text-2xl mt-5 border p-4 text-center'>“ বিশ্ব বাজারের জন্য শ্রেষ্ঠ মানের আউটপুট।”</p>
                                        </div>
                                        <div className='pb-4'>
                                                  <div className="border-l-4 border-red-500 bg-primary text-white py-3">
                                                            <h1 className='pl-4'>আমাদের লক্ষ্যঃ-</h1>
                                                  </div>
                                                  <p className='text-sm md:text-base mt-5 border p-4 text-center'>“ বাংলাদেশের প্রযুক্তিগত এবং আর্থ সামাজিক উন্নয়নে অবদান রাখার জন্য ব্যাপকভাবে শিল্পের সঙ্গে মান সম্মত শিক্ষা ও প্রশিক্ষণ, সঠিক নির্দেশনা এবং পরামর্শ, সহযোগিতা প্রদানের মাধ্যমে কাঞ্চন ভারত চন্দ্র উচ্চ বিদ্যালয়কে স্বনির্ভরশীল প্রমাণ করা। ”</p>
                                                  <p className='text-lg md:text-xl lg:text-2xl font-bold text-primary mt-5 border p-4 text-center'>মূল্যবোধঃ- নেতৃত্ব, ইনোভেশন, ত্যাগ, এক্সেলেন্স এবং প্রতিষ্ঠা।</p>
                                        </div>
                              </div>
                    </GlobalStyle>
          )
}
