import React from 'react'
import GlobalStyle from '../../shared/GlobalStyle/GlobalStyle'
import { useNavigate, useParams } from 'react-router-dom'
import { HiArrowNarrowLeft } from 'react-icons/hi'
import { notices } from '../../configs/globalData';
import useTitle from '../../hooks/useTitle';
import useScrollToTop from '../../hooks/useScrollToTop';

import { Viewer, ProgressBar, SpecialZoomLevel } from '@react-pdf-viewer/core';

import '@react-pdf-viewer/core/lib/styles/index.css';

export default function SingleNotice() {
          useTitle('নোটিশ বোর্ড');
          useScrollToTop();
          const { id } = useParams<{ id: string }>();
          const navigate = useNavigate();

          const handleDownloadPdf = (url: string, title: string) => {
                    fetch(url)
                              .then(res => res.blob())
                              .then(blob => {
                                        const fileUrl = window.URL.createObjectURL(blob);
                                        let alink = document.createElement('a');
                                        alink.href = fileUrl;
                                        alink.download = title;
                                        alink.click();
                              })

          }

          return (
                    <GlobalStyle>
                              <div className='my-5 md:my-0 px-2 lg:px-0'>
                                        <div className='flex justify-start'>
                                                  <button className='flex items-center justify-start gap-1 md:cursor-pointer' onClick={() => navigate('/notices')}><HiArrowNarrowLeft size={20} /> Back</button>
                                        </div>
                                        {
                                                  notices.map(notice => (
                                                            notice.id === id && (
                                                                      <div key={notice.id} className='pt-5'>
                                                                                <p>তারিখঃ <br />{notice.publishedDate}</p>
                                                                                <p className='pt-5'>বিষয়ঃ <br /><span className='font-bold'>{notice.title}</span></p>
                                                                                <p className='pt-5'>বিস্তারিতঃ </p>
                                                                                <div className='pt-5'>
                                                                                          <span onClick={() => handleDownloadPdf(notice.noticeURL, notice.title)} className='text-primary font-semibold text-xl md:cursor-pointer'> Alternative Download Link......... (Click me).</span>
                                                                                </div>
                                                                                <div className='mt-5 h-[70vh] md:h-screen border-2'>
                                                                                          <Viewer
                                                                                                    renderLoader={(percentages: number) => (
                                                                                                              <div style={{ width: '240px' }}>
                                                                                                                        <ProgressBar progress={Math.round(percentages)} />
                                                                                                              </div>
                                                                                                    )}
                                                                                                    defaultScale={window.innerWidth < 640 ? 1.2 : SpecialZoomLevel.PageWidth}
                                                                                                    fileUrl={notice.noticeURL}
                                                                                          />
                                                                                </div>
                                                                                <p className='pt-10 text-sm'>সর্বশেষ হাল-নাগাদঃ {notice.publishedDate}</p>
                                                                      </div>
                                                            )
                                                  ))
                                        }
                              </div>
                    </GlobalStyle>
          )
}
