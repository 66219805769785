import React from 'react'
import { createBrowserRouter } from 'react-router-dom';
import Root from './Layouts/Root';
import ShortIntro from './pages/AboutUs/Introduction/ShortIntro/ShortIntro';
import NotFound from './shared/NotFound';
import ContactUs from './pages/ContactUs/ContactUs';
import Notices from './pages/Notices/Notices';
import SingleNotice from './pages/Notices/SingleNotice';
import AimsAndObjectives from './pages/AboutUs/Introduction/AimsAndObjectives/AimsAndObjectives';
import HeadmasterMessage from './pages/AboutUs/Introduction/HeadmasterMessage/HeadmasterMessage';
import AssistantHeadmaster from './pages/AboutUs/Chiefs/AssistantHeadmaster/AssistantHeadmaster';
import FormerHeadmasters from './pages/AboutUs/Chiefs/FormerHeadmasters/FormerHeadmasters';
import ClassRoutine from './pages/Academic/ClassRoutine/ClassRoutine';
import ExamRoutine from './pages/Academic/ExamRoutine/ExamRoutine';
import Results from './pages/ExamResults/Results/Results';
import Gallery from './pages/Gallery/Gallery';
import PresentStudents from './pages/StudentInformation/PresentStudents/PresentStudents';
import FormerStudents from './pages/StudentInformation/FormerStudents/FormerStudents';
import StaffList from './pages/AboutUs/Employees/StaffList/StaffList';
import TeachersList from './pages/AboutUs/Employees/TeachersList/TeachersList';

export const router = createBrowserRouter(
          [
                    {
                              path: '/',
                              element: <Root />
                    },
                    {
                              path: '/notices',
                              element: <Notices />
                    },
                    {
                              path: '/notices/:id',
                              element: <SingleNotice />
                    },
                    {
                              path: '/short-intro',
                              element: <ShortIntro />
                    },
                    {
                              path: '/aims-and-objectives',
                              element: <AimsAndObjectives />
                    },
                    {
                              path: '/headmaster-message',
                              element: <HeadmasterMessage />
                    },
                    {
                              path: '/headmaster',
                              element: <HeadmasterMessage />
                    },
                    {
                              path: '/assistant-headmaster',
                              element: <AssistantHeadmaster />
                    },
                    {
                              path: '/former-headmasters',
                              element: <FormerHeadmasters />
                    },
                    {
                              path: '/teachers-list',
                              element: <TeachersList />
                    },
                    {
                              path: '/staffs-list',
                              element: <StaffList />
                    },
                    {
                              path: '/class-routine',
                              element: <ClassRoutine />
                    },
                    {
                              path: '/exam-routine',
                              element: <ExamRoutine />
                    },
                    {
                              path: '/results',
                              element: <Results />
                    },
                    {
                              path: '/gallery',
                              element: <Gallery />
                    },
                    {
                              path: '/present-students',
                              element: <PresentStudents />
                    },
                    {
                              path: '/former-students',
                              element: <FormerStudents />
                    },
                    {
                              path: '/contact-us',
                              element: <ContactUs />
                    },
                    {
                              path: '*',
                              element: <NotFound />
                    }
          ]
);
