import React from 'react'
import { BeatLoader } from 'react-spinners'
import Logo from '../assets/logo.png';

export default function Preloader() {
          return (
                    <div className="flex flex-col justify-center items-center h-screen">
                              <img src={Logo} alt="logo" draggable={false} className="w-20" />
                              <div className="flex justify-center items-center mt-2">
                                        <BeatLoader color="#000" size={10} />
                              </div>
                    </div>
          )
}
